module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-EEPZ0FD9TM","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"defer":false,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"justsloth.com","exclude":[],"enableWebVitalsTracking":false},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1024,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#71C88E","showSpinner":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"08시 20분 개발기록","short_name":"Dylan","start_url":"/","icon":"static/favicons/favicon-96x96.png","icons":[{"src":"/favicons/android-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/favicons/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/android-icon-192x192.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e53b8353601cf72e7c8960c761a56765"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
